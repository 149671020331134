import React, { useState } from 'react';

const apiUrl = `${window.location.protocol}//${window.location.hostname}/api/v1`;
const App = () => {
  const [data, setData] = useState([{}]);
  const [studentId, setStudentId] = useState('');
  const [surveyWave, setSurveyWave] = useState('');
  const [name, setName] = useState('');
  const [patronymic, setPatronymic] = useState(''); 
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    if (studentId && surveyWave !== '0') {
      fetch(`${apiUrl}/get_answers?student_id=${studentId}&survey_wave=${surveyWave}&name=${name}&patronymic=${patronymic}`)
        .then((res) => res.json())
        .then((responseData) => {
          setData(responseData);
          setButtonClicked(true);
          setIsLoading(false);
        });
    } else {
      setData({});
      setIsLoading(false);
    }
  };

  const handleFetchButtonClick = () => {
    fetchData();
  };

  return (
    <div>
      <div className='nav'>
        <input
          type="text"
          value={studentId}
          placeholder='Ваш ID'
          onChange={(e) => setStudentId(e.target.value)}
        />
        </div>
        <div>
        <input
          type="text"
          value={name}
          placeholder='Имя'
          onChange={(e) => setName(e.target.value)}
        />
        </div>
        <div>
        <input
          type="text"
          value={patronymic}
          placeholder='Отчество'
          onChange={(e) => setPatronymic(e.target.value)}
        />
      </div>
      <div>
        <select
          value={surveyWave}
          onChange={(e) => setSurveyWave(e.target.value)}
        >
          <option value="0">Волна</option>
          <option value="1">Волна 1 (2011)</option>
          <option value="2">Волна 2 (2013)</option>
          <option value="3">Волна 3 (2013)</option>
          <option value="4">Волна 4 (2014)</option>
          <option value="5">Волна 5 (2016)</option>
          <option value="6">Волна 6 (2017)</option>
          <option value="7">Волна 7 (2018)</option>
          <option value="8">Волна 8 (2019)</option>
          <option value="9">Волна 9 (2020)</option>
          <option value="10">Волна 10 (2021)</option>
        </select>
      </div>

      <button onClick={handleFetchButtonClick} disabled={surveyWave === '0'}>Получить данные</button>
      {isLoading ? (
        <p>Loading...</p>
      ) : buttonClicked && Object.entries(data).length === 0 ? (
        <p>К сожалению, мы не нашли ваших ответов для выбранной волны. Выберите другую волну, в которой вы точно участвовали или проверьте правильность введенных данных.</p>
      ) : buttonClicked ? (
        Object.entries(data).map(([question, answer], index) => {
          return (
            <div key={index}>
              <p class="question">{`${index + 1}. ${question}`}</p>
              <p id='answer'>{answer}</p>
            </div>
          );
        })
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default App;
